@keyframes bottomToTop {
    from {
      opacity: 0;
      transform: translateY(115%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @keyframes topToBottom {
    from {
      opacity: 1;
      transform: translateY(0%);
    }
    to {
      opacity: 0;
      transform: translateY(115%);
      visibility: hidden;
    }
  }


  @keyframes rightToLeft {
    from {
      opacity: 0;
      transform: translateX(115%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }



  @keyframes leftToRigth {
    from {
      opacity: 1;
      transform: translateX(60%);
    }
    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }


  @keyframes slotMachine {
    0% { transform: rotateX(0); }
  100% { transform: rotateX(360deg); }
  }
  
  .slot-machine {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    animation: slotMachine 1s ease-in-out;
  }
  
  .number {
    display: inline-block;
    transition: transform 1s;
    height: 100%;
  }
  