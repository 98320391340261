.heartIcon {
    position: absolute;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    width: 2vw;
    height: 2vw;
    min-width: 30px;
    min-height: 30px;
}

@media (min-width: 1920px) {
    .heartIcon {
        width: 45px;
        height: 45px;
    }
}


@media (max-width: 800px) {
    .heartIcon {
        width: 25px;
        height: 25px;
    }
}
