.img-order-layout .linear_gradient {
    font-family: 'Pretendard';
    font-weight: 700;
    font-size: 3vw;
}
.img-order-layout .flex_column {
    display: "flex";
    flex-direction: "column";
}
.img-order-layout .flex_column .title {
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 3vw;
    color: #fff;
    display: inherit;
}

.img-order-layout .flex_column .nfts {
    font-family: 'Pretendard';
    font-weight: 700;
    font-size: 3vw;
    color: '#fff'
    
}

.img-decorate-1 {
    position: absolute;
    left: -30px;
    top: 33%;
    width: 13%;
    /* height: fit-content; */
    transform: translate(0, -33%);
}

.img-decorate-2 {
    position: absolute;
    right: 10px;
    bottom: -3%;
    width: 6%;
    /* height: fit-content; */
    /* transform: translate(0, 4%); */
}

@media (min-width: 1920px) {
    .img-order-layout .flex_column .title {
        font-size: 60px;
        line-height: 64px;

    }
    .img-order-layout .flex_column .nfts {
        font-size: 60px;
        line-height: 64px;
    }


  }