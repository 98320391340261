.y-axis {
    font-size: 1.2vw !important;
}

@media (min-width: 1920px) {
    .y-axis {
        font-size: 23px !important;
    }
  }


  @media (max-width: 680px) {
    .y-axis {
        font-size: 14px !important;
    }
  }