

.load-modal-container{
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10vh;
  flex-direction: column;
}

.modal-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 100001;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    height: 90vh;
    object-fit: contain;
  }
}
